import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { SetClassificationForEdit } from '../../../../classification/classification-scheme.actions';
import { ClassificationScheme } from '../../../../classification/classification-scheme.model';
import { Classification } from '../../../../classification/classification.model';
import { ClassificationService } from '../../../../classification/classification.service';
import { PercentagePipe } from '../../../../pipes/percentage.pipe';
import { ScandataModel } from '../../../../scandata/scandata.models';
import { SetView } from '../../../options-panel.actions';
import { OptionsPanelView } from '../../../options-panel.state';
import { ClassificationGraphComponent } from './classification-graph/classification-graph.component';

@UntilDestroy()
@Component({
  selector: 'sd-classification',
  standalone: true,
  imports: [
    CommonModule,
    ClassificationGraphComponent,
    PercentagePipe,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
  ],
  templateUrl: './classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationComponent {
  scandataModel = input.required<ScandataModel>();
  readonly = input(false);

  private classificationSchemes = toSignal(this.classificationService.getClassificationSchemes());
  classifications = this.getClassifications();
  hasClassifications = computed(() => this.classifications().length > 0);
  unallocatedClassificationCount = this.getUnallocatedClassificationCount();

  activeClassification = signal<Classification | null>(null);

  constructor(
    private classificationService: ClassificationService,
    private store: Store,
  ) {}

  setActiveClassification(classification: Classification | null = null) {
    this.activeClassification.set(classification);
  }

  isActiveClassification(classification: Classification) {
    return classification === this.activeClassification();
  }

  editClassification(classificationScheme: ClassificationScheme) {
    this.store.dispatch([
      new SetClassificationForEdit(classificationScheme),
      new SetView(OptionsPanelView.ClassificationEdit, false),
    ]);
  }

  private getClassifications() {
    return computed(() => {
      const scan = this.scandataModel();
      const schemes = this.classificationSchemes();
      return scan && schemes
        ? this.classificationService.getScanClassifications(scan, schemes)
        : [];
    });
  }

  private getUnallocatedClassificationCount() {
    return computed(() => {
      const schemes = this.classificationSchemes();
      const classifications = this.classifications();
      if (isNil(schemes) || schemes.length < classifications.length) return 0;
      return schemes.length - classifications.length;
    });
  }
}
