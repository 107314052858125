<div class="flex flex-col h-full mr-2">
  <div class="flex w-full">
    <modus-label class="text-xs grow">Classes & Point Styling</modus-label>
    <modus-switch
      id="3d-settings-classification-show-toggle"
      [formControl]="formGroup.controls.showClassification"
    ></modus-switch>
  </div>

  <div class="flex w-full mt-3">
    <modus-label class="text-xs grow">Intensity Styling</modus-label>
    <modus-switch
      id="3d-settings-intensity-toggle"
      [formControl]="formGroup.controls.showIntensity"
    ></modus-switch>
  </div>

  <div class="flex w-full mt-3">
    <modus-label class="text-xs grow">Eye dome lighting</modus-label>
    <modus-switch
      id="3d-settings-eye-dome-toggle"
      [formControl]="formGroup.controls.showEyeDomeLighting"
    ></modus-switch>
  </div>

  <div class="flex w-full items-center">
    <modus-label class="text-xs grow select-none" (click)="showExperimentalClick($event)"
      >Point Size</modus-label
    >
    <mat-slider id="3d-settings-point-size" [min]="1" [max]="10" [step]="1" class="min-w-[60px]">
      <input modus-input matSliderThumb [formControl]="formGroup.controls.pointSize" />
    </mat-slider>
  </div>

  @if (showExperimental()) {
    <div class="flex flex-col border-t border-gray-1 text-sm">
      <div class="flex w-full justify-center mt-3">Experimental</div>

      <div class="flex w-full items-center">
        <modus-label class="text-xs grow">Point Budget</modus-label>
        <mat-slider
          id="3d-settings-point-budget"
          [min]="10000000"
          [max]="40000000"
          [step]="10000000"
          class="min-w-[60px] h-8"
        >
          <input modus-input matSliderThumb [formControl]="formGroup.controls.pointBudget" />
        </mat-slider>
      </div>

      <div class="flex w-full items-center">
        <modus-label class="text-xs grow">Point Density</modus-label>
        <mat-slider
          id="3d-settings-point-density"
          [min]="0.5"
          [max]="3.0"
          [step]="0.5"
          class="min-w-[60px] h-8"
        >
          <input
            class="max-h-8"
            modus-input
            matSliderThumb
            [formControl]="formGroup.controls.pointDensity"
          />
        </mat-slider>
      </div>

      @if (showIntensitySettings()) {
        <div class="flex w-full items-center mb-2">
          <modus-label class="text-xs grow select-none">Intensity Gradient</modus-label>
          <div class="h-12">
            <mat-slider
              id="3d-settings-intensity-gradient"
              [min]="0"
              [max]="1"
              [step]="0.1"
              class="mx-1"
            >
              <input
                modus-input
                matSliderThumb
                [formControl]="formGroup.controls.intensityOffset"
              />
            </mat-slider>

            <div class="flex justify-between relative -top-[14px]">
              <div>
                <div
                  id="3d-settings-intensity-start-color"
                  colorPicker="{{ stripAlpha(formGroup.controls.intensityStartColor.value) }}"
                  [cpOutputFormat]="'hex'"
                  [cpAlphaChannel]="'disabled'"
                  [cpPosition]="'top-left'"
                  [cpCancelButton]="true"
                  [cpCancelButtonClass]="'modus-button modus-button-medium modus-basic-button px-2'"
                  [cpOKButton]="true"
                  [cpOKButtonClass]="'modus-button modus-button-medium modus-flat-button-primary'"
                  [cpWidth]="'155'"
                  (colorPickerChange)="changeIntensityStartColor($event)"
                  class="h-4 w-4 rounded-lg cursor-pointer border border-gray-1 ml-[3px]"
                  style="background-color: {{ formGroup.controls.intensityStartColor.value }}"
                ></div>
              </div>

              <div>
                <div
                  id="3d-settings-intensity-end-color"
                  colorPicker="{{ stripAlpha(formGroup.controls.intensityEndColor.value) }}"
                  [cpOutputFormat]="'hex'"
                  [cpAlphaChannel]="'disabled'"
                  [cpPosition]="'top-left'"
                  [cpCancelButton]="true"
                  [cpCancelButtonClass]="'modus-button modus-button-medium modus-basic-button'"
                  [cpOKButton]="true"
                  [cpOKButtonClass]="'modus-button modus-button-medium modus-flat-button-primary'"
                  [cpWidth]="'180'"
                  (colorPickerChange)="changeIntensityEndColor($event)"
                  style="background-color: {{ formGroup.controls.intensityEndColor.value }}"
                  class="h-4 w-4 rounded-lg cursor-pointer border border-gray-1 mr-[3px]"
                ></div>
              </div>
            </div>
          </div>
        </div>
      }

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">EDL Radius</modus-label>
        <modus-label class="text-xs">1.4</modus-label>
      </div>

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">EDL Strength</modus-label>
        <modus-label class="text-xs">0.7</modus-label>
      </div>

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">Point Budget</modus-label>
        <modus-label class="text-xs">{{ formGroup.controls.pointBudget.value }}</modus-label>
      </div>

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">Point Density</modus-label>
        <modus-label class="text-xs">{{ formGroup.controls.pointDensity.value }}</modus-label>
      </div>

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">Point Size</modus-label>
        <modus-label class="text-xs">{{ formGroup.controls.pointSize.value }}</modus-label>
      </div>

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">Point Shape</modus-label>
        <modus-label class="text-xs">Paraboloid</modus-label>
      </div>

      <div class="flex w-full items-center mt-2">
        <modus-label class="text-xs grow">Size Attenuation</modus-label>
        <modus-label class="text-xs">Off</modus-label>
      </div>
    </div>
  }
</div>
